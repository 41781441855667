@charset "ISO-8859-1";

html {
  scroll-behavior: smooth !important;
  font-family: "Square Peg", cursive !important;
}
body::-webkit-scrollbar {
  width: 14px;
}

body::-webkit-scrollbar-track {
  background: #043432;
}

body::-webkit-scrollbar-thumb {
  background-color: #13ac98;
  border-radius: 20px;
  border: 3px solid #161f1d;
}

a {
  text-decoration: none !important;
  cursor: pointer !important;
}
.wrapper {
  margin: 0 auto;
  width: 100%;
}

.homePage {
  position: absolute !important;
  top: 0% !important;
}

.appBar {
  background-color: transparent;
}

.backdrop-gradient {
  background: #ffffff !important;
  opacity: 0.8 !important;
}

.backdrop-gradient2 {
  background: #ffffff !important;
  opacity: 0.4 !important;
}

.page-backdrop {
  opacity: 1 !important;
  background-attachment: fixed !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  filter: saturate(2.5) !important;
}

.footer {
  position: absolute !important;
  bottom: 1% !important;
}

.contact-animated {
  border-radius: 50% !important;
  background-color: #04b19a !important;
  opacity: 0.7 !important;
}

.contact-animated:hover .contact-icon {
  content: "";
  transition: 0.2s linear !important;
  border-radius: 50% !important;
  font-size: 40px !important;
  background-color: #04b19a !important;
}

.scrollTop-animated {
  border-radius: 50% !important;
  background-color: #04b19a !important;
  opacity: 0.7 !important;
}

.scrollTop-animated:hover .scrollTop-icon {
  content: "";
  transition: 0.2s linear !important;
  border-radius: 50% !important;
  font-size: 40px !important;
  background-color: #04b19a !important;
}

.close-text-animate:hover .icon-animate {
  animation: rotate 0.2s linear;
  color: #000000 !important;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(180deg);
  }
}

.close-text-animate:hover {
  color: #010101 !important;
}

.link:hover {
  color: #e8b21d !important;
  font-size: 41px !important;
  transition: ease-in 0.1s !important;
  cursor: pointer !important;
}

.site-text {
  font-family: "Noto Sans", sans-serif !important;
}

.other-text {
  font-family: "Black Ops One", cursive !important;
  font-weight: 600 !important;
}

.new-text {
  font-family: "Playfair Display", serif !important;
}

.mini-link:hover {
  text-decoration: #c39928 underline !important;
}

.img-container {
  position: absolute !important;
  margin-top: -130px !important;
}

.menu-icon:hover .menu-icon-child {
  color: #5c5c5c !important;
  cursor: pointer !important;
}

.site-logo:hover p {
  color: #5c5c5c !important;
  cursor: pointer !important;
}

.site-logo2:hover p {
  color: #c8c8c8 !important;
  cursor: pointer !important;
}

.site-log:hover img {
  filter: grayscale(80%) !important;
}

.contact-link {
  cursor: pointer !important;
}

.contact-link:hover {
  text-decoration: #aa771c underline !important;
  -moz-text-decoration: #aa771c underline !important;
  -moz-text-decoration: #aa771c underline !important;
}

.next-link {
  cursor: pointer !important;
}

.next-link:hover {
  text-decoration: #e8b21d underline !important;
}

.image-fadeout {
  opacity: 0.4 !important;
  transition-delay: 0.1s !important;
}

.project-container {
  position: absolute !important;
}

.card-container {
  position: relative !important;
  cursor: pointer !important;
}

.card-text {
  position: absolute !important;
  top: 0% !important;
}

.card-container:hover .img-card {
  opacity: 0.9 !important;
  cursor: pointer !important;
}

.card-container:hover .card-text {
  background: linear-gradient(
    90deg,
    rgb(8, 15, 23) 0%,
    rgb(14, 18, 23) 39%,
    rgb(14, 35, 50) 100%
  );
  width: auto !important;
  height: 100% !important;
  opacity: 0.8 !important;
  top: 0% !important;
}

.card-container:hover .view-project {
  display: flex !important;
  animation: scale 0.3s ease-in !important;
}

.card-container:hover .card-text-child {
  display: flex !important;
  animation: scale 0.3s ease-in !important;
}

.card-container:hover .card-text-title {
  display: flex !important;
  animation: scale 0.3s ease-in !important;
}

@keyframes scale {
  0% {
    transform: scaleY(0%);
  }

  100% {
    transform: scaleY(100%);
  }
}

.client-icon-button:hover {
  background-color: #eab811 !important;
  opacity: 0.9 !important;
}

.client-icon-button:hover .client-text {
  color: #ffffff !important;
}

.footer-link:hover {
  color: #eab811 !important;
  transition: 0.2s ease-in !important;
}

.image-effect-main {
  -webkit-filter: blur(10px) grayscale(80%);
  filter: blur(10px) grayscale(80%);
}

.img-container {
  position: relative !important;
  cursor: pointer !important;
}

.img-overlay-prev-icon {
  position: absolute !important;
  top: 40% !important;
  width: 100% !important;
  transition: 0.5s ease !important;
  opacity: 1 !important;
  cursor: pointer !important;
  justify-content: flex-start !important;
}

.img-hover-zoom:hover {
  transform: scale(1.1);
  cursor: pointer !important;
  transition: 0.3s ease !important;
}

.link-text:hover {
  color: #ffbf0d !important;
  cursor: pointer !important;
  transition: 0.3s ease !important;
}

.img-container:hover .img-overlay-prev-icon,
.img-container:hover .img-overlay-next-icon {
  display: flex !important;
  transition: ease-in 1s !important;
  opacity: 0.5 !important;
}

.img-overlay-next-icon {
  position: absolute !important;
  top: 40% !important;
  width: 100% !important;
  transition: 0.5s ease !important;
  opacity: 1 !important;
  cursor: pointer !important;
  justify-content: flex-end !important;
}

.avatar-overlay {
  position: absolute !important;
  top: 20% !important;
  transition: 0.5s ease !important;
  opacity: 1 !important;
  cursor: pointer !important;
  text-align: center !important;
  align-items: center !important;
  left: inherit;
}

.img-effect {
  -webkit-filter: grayscale(70%) !important;
  filter: grayscale(70%) !important;
}

.golden-gradient {
  background: linear-gradient(
    to right,
    #4e3526 0,
    #cb9b51 22%,
    #cb9b51 45%,
    #cb9b51 50%,
    #cb9b51 85%,
    #cb9b51 78%,
    #462523 70%
  ) !important;
}

.bgimg-1,
.bgimg-2,
.bgimg-3 {
  position: relative !important;
  opacity: 0.85 !important;
  background-attachment: fixed !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.bgimg-1 {
  min-height: 400px !important;
}

.bgimg-2 {
  filter: grayscale(80%) !important;
  min-height: 400px !important;
}

.bgimg-3 {
  z-index: 1 !important;
  min-height: 550px !important;
  background-attachment: fixed !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  -webkit-filter: saturate(1.7) !important;
  filter: saturate(1.7) !important;
}

.caption {
  position: absolute !important;
  left: 0 !important;
  top: 50% !important;
  width: 100% !important;
  text-align: center !important;
  color: #000 !important;
}

.caption span.border {
  background: linear-gradient(
    to right,
    #462523 0,
    #cb9b51 42%,
    #cb9b51 45%,
    #cb9b51 50%,
    #cb9b51 85%,
    #cb9b51 78%,
    #462523 100%
  ) !important;
  color: #fff !important;
  padding: 10px !important;
  font-size: 25px !important;
  letter-spacing: 3px !important;
  font-weight: 800 !important;
}

.secondary-btn-hover:hover {
  background-color: #eab811 !important;
  color: #ffffff !important;
}

.footer-background2 {
  background: -webkit-linear-gradient(70deg, #084243 30%, rgba(0, 0, 0, 0) 30%),
    -webkit-linear-gradient(-160deg, #084243 60%, #014f4b 60%) !important;
  background: -o-linear-gradient(70deg, #014f4b 30%, rgba(0, 0, 0, 0) 30%),
    -o-linear-gradient(-160deg, #084243 60%, #014f4b 60%) !important;
  background: -moz-linear-gradient(70deg, #014f4b 30%, rgba(0, 0, 0, 0) 30%),
    -moz-linear-gradient(-160deg, #084243 60%, #014f4b 60%) !important;
  background: linear-gradient(70deg, #014f4b 30%, rgba(0, 0, 0, 0) 30%),
    linear-gradient(-160deg, #084243 60%, #014f4b 60%) !important;
}

.footer-background {
  background: -webkit-linear-gradient(70deg, #084243 30%, rgba(0, 0, 0, 0) 30%),
    -webkit-linear-gradient(-160deg, #084243 60%, #014f4b 60%) !important;
  background: -o-linear-gradient(70deg, #014f4b 30%, rgba(0, 0, 0, 0) 30%),
    -o-linear-gradient(-160deg, #084243 60%, #014f4b 60%) !important;
  background: -moz-linear-gradient(70deg, #014f4b 30%, rgba(0, 0, 0, 0) 30%),
    -moz-linear-gradient(-160deg, #084243 60%, #055753 60%) !important;
  background: linear-gradient(60deg, #025b56 30%, rgba(237, 245, 241, 0) 30%),
    linear-gradient(-150deg, #084243 55%, #06514f 40%) !important;
  filter: saturate(3) !important;
}

.bg-pattern1 {
  background: radial-gradient(
        hsl(180, 98%, 17%) 4%,
        hsl(171, 100%, 18%) 9%,
        hsla(0, 100%, 20%, 0) 9%
      )
      0 0,
    radial-gradient(
        hsl(171, 96%, 22%) 4%,
        hsl(169, 100%, 18%) 8%,
        hsla(0, 100%, 20%, 0) 10%
      )
      50px 50px,
    radial-gradient(hsla(174, 96%, 22%, 0.8) 20%, hsla(0, 100%, 20%, 0)) 50px 0,
    radial-gradient(hsla(169, 100%, 30%, 0.8) 20%, hsla(0, 100%, 20%, 0)) 0 50px,
    radial-gradient(rgb(0, 102, 92) 35%, hsla(0, 100%, 20%, 0) 60%) 50px 0,
    radial-gradient(rgb(0, 102, 97) 35%, hsla(0, 100%, 20%, 0) 60%) 100px 50px,
    radial-gradient(hsla(174, 100%, 15%, 0.7), hsla(0, 100%, 20%, 0)) 0 0,
    radial-gradient(hsla(179, 100%, 15%, 0.7), hsla(0, 100%, 20%, 0)) 50px 50px,
    linear-gradient(
        45deg,
        hsla(0, 100%, 20%, 0) 49%,
        hsla(0, 100%, 0%, 1) 50%,
        hsla(0, 100%, 20%, 0) 70%
      )
      0 0,
    linear-gradient(
        -45deg,
        hsla(0, 100%, 20%, 0) 49%,
        hsla(0, 100%, 0%, 1) 50%,
        hsla(0, 100%, 20%, 0) 70%
      )
      0 0 !important;
  background-color: rgb(0, 51, 43) !important;
  background-size: 100px 110px !important;
}

.content-background {
  background: -webkit-linear-gradient(140deg, #607559 60%, rgba(0, 0, 0, 0) 30%),
    -webkit-radial-gradient(farthest-corner at 0% 0%, #607559 70%, #3f493c 70%);
  background: -o-linear-gradient(140deg, #607559 40%, rgba(0, 0, 0, 0) 30%),
    -o-radial-gradient(farthest-corner at 0% 0%, #607559 70%, #3f493c 70%) !important;
  background: -moz-linear-gradient(140deg, #607559 40%, rgba(0, 0, 0, 0) 30%),
    -moz-radial-gradient(farthest-corner at 0% 0%, #607559 70%, #3f493c 70%) !important;
  background: linear-gradient(140deg, #607559 40%, rgba(0, 0, 0, 0) 30%),
    radial-gradient(farthest-corner at 0% 0%, #607559 70%, #3f493c 70%) !important;
}

.bg-aurora {
  color: #fff !important;
  background: -webkit-linear-gradient(70deg, #3f493c 30%, rgba(0, 0, 0, 0) 80%),
    url("../images/agri.jpeg") !important;
  background: -o-linear-gradient(70deg, #3f493c 30%, rgba(0, 0, 0, 0) 80%),
    url("../images/agri.jpeg") !important;
  background: -moz-linear-gradient(70deg, #3f493c 30%, rgba(0, 0, 0, 0) 80%),
    url("../images/agri.jpeg") !important;
  background: linear-gradient(70deg, #3f493c 30%, rgba(0, 0, 0, 0) 80%),
    url("../images/agri.jpeg") !important;
  background-size: cover !important;
  background-position: 60% 21% !important;
  -webkit-filter: saturate(2) !important;
  filter: saturate(2) !important;
}

.bg-aurora2 {
  color: #fff !important;
  background: -webkit-linear-gradient(
      0deg,
      rgba(25, 33, 31, 0.9) 25%,
      rgba(26, 29, 34, 0.5) 80%
    ),
    url("../images/agri.jpeg") !important;
  background: -o-linear-gradient(
      0deg,
      rgba(25, 33, 31, 0.9) 25%,
      rgba(26, 29, 34, 0.5) 80%
    ),
    url("../images/agri.jpeg") !important;
  background: -moz-linear-gradient(
      0deg,
      rgba(25, 33, 31, 0.9) 25%,
      rgba(26, 29, 34, 0.5) 80%
    ),
    url("../images/agri.jpeg") !important;
  background: linear-gradient(
      0deg,
      rgba(25, 33, 31, 0.9) 25%,
      rgba(26, 29, 34, 0.5) 80%
    ),
    url("../images/agri.jpeg") !important;
  background-size: cover !important;
  background-position: 80% 21% !important;
  -webkit-filter: saturate(1.8) !important;
  filter: saturate(1.8) !important;
}

.clip-box {
  clip-path: polygon(
    30% 0%,
    70% 0%,
    100% 0,
    100% 90%,
    70% 100%,
    30% 100%,
    0 90%,
    0 0
  ) !important;
}

.clip-box2 {
  clip-path: polygon(
    50% 0%,
    85% 5%,
    100% 7%,
    100% 100%,
    68% 100%,
    32% 100%,
    0 100%,
    0 3%,
    16% 5%
  ) !important;
  box-shadow: #00a8c5 !important;
}

/* ---------- Fog ---------- */
.fogwrapper {
  position: absolute;
  top: 0;
  width: 100%;
  -webkit-filter: blur(1px) grayscale(0.2) saturate(1.2) sepia(0.2);
  filter: blur(5px) grayscale(1.5) saturate(3.5) sepia(0.9);
}

@media (max-width: 400px) {
  #foglayer_01,
  #foglayer_02,
  #foglayer_03 {
    height: 100%;
    position: absolute;
    top: -10%;
    width: 100%;
  }
}

@media (min-width: 450px) {
  #foglayer_01,
  #foglayer_02,
  #foglayer_03 {
    height: 100%;
    position: absolute;
    top: -1%;
    width: 100%;
  }
}
#foglayer_01 .image01,
#foglayer_01 .image02,
#foglayer_02 .image01,
#foglayer_02 .image02,
#foglayer_03 .image01,
#foglayer_03 .image02 {
  float: left;
  height: 100%;
  width: 100%;
}
#foglayer_01 .image01,
#foglayer_01 .image02,
#foglayer_02 .image01,
#foglayer_02 .image02,
#foglayer_03 .image01,
#foglayer_03 .image02 {
  float: left;
  height: 100%;
  width: 50%;
}
#foglayer_01 {
  -webkit-animation: foglayer_01_opacity 10s linear infinite,
    foglayer_moveme 15s linear infinite;
  -moz-animation: foglayer_01_opacity 10s linear infinite,
    foglayer_moveme 15s linear infinite;
  animation: foglayer_01_opacity 10s linear infinite,
    foglayer_moveme 15s linear infinite;
}
#foglayer_02,
#foglayer_03 {
  -webkit-animation: foglayer_02_opacity 21s linear infinite,
    foglayer_moveme 13s linear infinite;
  -moz-animation: foglayer_02_opacity 21s linear infinite,
    foglayer_moveme 13s linear infinite;
  animation: foglayer_02_opacity 21s linear infinite,
    foglayer_moveme 13s linear infinite;
}

/* ---------- Moving Fog ---------- */
/*
  'size: cover' || 'size: 100%'; results remain the same
  'attachment: scroll' can be added or removed; results remain the same
  'attachment: fixed' causing unexpected results in Chrome
  'repeat-x' || 'no-repeat'; results remain the same
*/
#foglayer_01 .image01,
#foglayer_01 .image02 {
  background: url("https://raw.githubusercontent.com/danielstuart14/CSS_FOG_ANIMATION/master/fog1.png")
    center center/cover no-repeat transparent;
}
#foglayer_02 .image01,
#foglayer_02 .image02,
#foglayer_03 .image01,
#foglayer_03 .image02 {
  background: url("https://raw.githubusercontent.com/danielstuart14/CSS_FOG_ANIMATION/master/fog2.png")
    center center/cover no-repeat transparent;
}

/* ---------- Keyframe Layer 1 ---------- */
@-webkit-keyframes foglayer_01_opacity {
  0% {
    opacity: 0.1;
  }
  22% {
    opacity: 0.7;
  }
  40% {
    opacity: 0.28;
  }
  58% {
    opacity: 0.6;
  }
  80% {
    opacity: 0.16;
  }
  100% {
    opacity: 0.1;
  }
}
@-moz-keyframes foglayer_01_opacity {
  0% {
    opacity: 0.1;
  }
  22% {
    opacity: 0.7;
  }
  40% {
    opacity: 0.28;
  }
  58% {
    opacity: 0.6;
  }
  80% {
    opacity: 0.16;
  }
  100% {
    opacity: 0.1;
  }
}
@-o-keyframes foglayer_01_opacity {
  0% {
    opacity: 0.1;
  }
  22% {
    opacity: 0.7;
  }
  40% {
    opacity: 0.28;
  }
  58% {
    opacity: 0.6;
  }
  80% {
    opacity: 0.16;
  }
  100% {
    opacity: 0.1;
  }
}
@keyframes foglayer_01_opacity {
  0% {
    opacity: 0.1;
  }
  22% {
    opacity: 0.7;
  }
  40% {
    opacity: 0.28;
  }
  58% {
    opacity: 0.4;
  }
  80% {
    opacity: 0.16;
  }
  100% {
    opacity: 0.1;
  }
}
/* ---------- Keyframe Layer 2 ---------- */
@-webkit-keyframes foglayer_02_opacity {
  0% {
    opacity: 0.7;
  }
  25% {
    opacity: 0.2;
  }
  50% {
    opacity: 0.1;
  }
  80% {
    opacity: 0.3;
  }
  100% {
    opacity: 0.7;
  }
}
@-moz-keyframes foglayer_02_opacity {
  0% {
    opacity: 0.7;
  }
  25% {
    opacity: 0.2;
  }
  50% {
    opacity: 0.1;
  }
  80% {
    opacity: 0.3;
  }
  100% {
    opacity: 0.7;
  }
}
@-o-keyframes foglayer_02_opacity {
  0% {
    opacity: 0.7;
  }
  25% {
    opacity: 0.2;
  }
  50% {
    opacity: 0.1;
  }
  80% {
    opacity: 0.3;
  }
  100% {
    opacity: 0.7;
  }
}
@keyframes foglayer_02_opacity {
  0% {
    opacity: 0.7;
  }
  25% {
    opacity: 0.2;
  }
  50% {
    opacity: 0.1;
  }
  80% {
    opacity: 0.3;
  }
  100% {
    opacity: 0.7;
  }
}
/* ---------- Keyframe Layer 3 ---------- */
@-webkit-keyframes foglayer_03_opacity {
  0% {
    opacity: 0.8;
  }
  27% {
    opacity: 0.2;
  }
  52% {
    opacity: 0.6;
  }
  68% {
    opacity: 0.3;
  }
  100% {
    opacity: 0.8;
  }
}
@-moz-keyframes foglayer_03_opacity {
  0% {
    opacity: 0.8;
  }
  27% {
    opacity: 0.2;
  }
  52% {
    opacity: 0.6;
  }
  68% {
    opacity: 0.3;
  }
  100% {
    opacity: 0.8;
  }
}
@-o-keyframes foglayer_03_opacity {
  0% {
    opacity: 0.8;
  }
  27% {
    opacity: 0.2;
  }
  52% {
    opacity: 0.6;
  }
  68% {
    opacity: 0.3;
  }
  100% {
    opacity: 0.8;
  }
}
@keyframes foglayer_03_opacity {
  0% {
    opacity: 0.8;
  }
  27% {
    opacity: 0.2;
  }
  52% {
    opacity: 0.6;
  }
  68% {
    opacity: 0.3;
  }
  100% {
    opacity: 0.8;
  }
}
/* ---------- Keyframe moveMe ---------- */
@-webkit-keyframes foglayer_moveme {
  0% {
    left: 0;
  }
  100% {
    left: -100%;
  }
}
@-moz-keyframes foglayer_moveme {
  0% {
    left: 0;
  }
  100% {
    left: -100%;
  }
}
@-o-keyframes foglayer_moveme {
  0% {
    left: 0;
  }
  100% {
    left: -100%;
  }
}
@keyframes foglayer_moveme {
  0% {
    left: 0;
  }
  100% {
    left: -100%;
  }
}

@media only screen and (min-width: 280px) and (max-width: 767px) {
  #foglayer_01 .image01,
  #foglayer_01 .image02,
  #foglayer_02 .image01,
  #foglayer_02 .image02,
  #foglayer_03 .image01,
  #foglayer_03 .image02 {
    width: 100%;
  }
}

.saturated-btn {
  -webkit-filter: saturate(1) !important;
  filter: saturate(1) !important;
}

.example {
  position: relative;
  width: 100%;
  padding-top: 50%;
  margin: 2rem auto;
}
.example > .block {
  position: absolute;
  height: 100%;
  width: 30%;
  perspective: 1000px;
}
.example > .block:nth-of-type(1) {
  height: 80%;
  top: 10%;
  left: 17%;
  width: 25%;
}
.example > .block:nth-of-type(2) {
  top: 0;
  left: 35%;
}
.example > .block:nth-of-type(3) {
  height: 80%;
  top: 10%;
  left: 64%;
  width: 25%;
}
.example > .block > .side {
  position: absolute;
  top: 0;
  left: 0;
  background-image: url("../images/12.jpeg");
  background-size: auto 100%;
  box-shadow: -1vw 0.5vw 1vw rgba(0, 0, 0, 0.3);
}
.example > .block > .side.-main {
  height: 100%;
  width: 100%;
  transform: rotateY(30deg);
  transform-origin: 0 50%;
}
.example > .block > .side.-left {
  height: 100%;
  width: 20%;
  transform-origin: 0 50%;
  transform: rotateY(-60deg) translateX(-100%);
  filter: brightness(40%);
}
.example > .block:nth-of-type(1) > .side.-main {
  background-position: 4% 50%;
  background-size: auto 130%;
}
.example > .block:nth-of-type(1) > .side.-left {
  background-position: 0 50%;
  background-size: auto 130%;
}
.example > .block:nth-of-type(2) > .side.-main {
  background-position: 50% 0;
}
.example > .block:nth-of-type(2) > .side.-left {
  background-position: 28.5% 0;
}
.example > .block:nth-of-type(3) > .side.-main {
  background-position: 96% 50%;
  background-size: auto 130%;
}
.example > .block:nth-of-type(3) > .side.-left {
  background-position: 78% 50%;
  background-size: auto 130%;
}

.btn-hover.btn-color-5 {
  background-image: linear-gradient(
    to right,
    #0eb087,
    #1eaa85,
    #1bc19a,
    #24dab5
  ) !important;
  box-shadow: 0 2px 7px 0 rgba(23, 129, 109, 0.75) !important;
}

.box-shadow1 {
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
    rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset !important;
}

.box-shadow2 {
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
    rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset !important;
}

.box-shadow3 {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset,
    rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset !important;
}

.box-shadow4 {
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px !important;
}

.box-shadow5 {
  box-shadow: rgba(240, 46, 170, 0.4) -5px 5px,
    rgba(240, 46, 170, 0.3) -10px 10px, rgba(240, 46, 170, 0.2) -15px 15px,
    rgba(240, 46, 170, 0.1) -20px 20px, rgba(240, 46, 170, 0.05) -25px 25px !important;
}

.text-effect {
  font-size: calc(20px + 18vh);
  line-height: calc(20px + 20vh);
  /*   text-shadow: 0 0 5px #f562ff, 0 0 15px #f562ff, 0 0 25px #f562ff,
    0 0 20px #f562ff, 0 0 30px #890092, 0 0 80px #890092, 0 0 80px #890092;
  color: #fccaff; */
  text-shadow: 0 0 5px #1fbf92, 0 0 15px #108161, 0 0 20px #108161,
    0 0 40px #108161, 0 0 60px #108161, 0 0 10px #108161, 0 0 98px #108161;
  color: #ffffff;
  font-family: "Sacramento", cursive;
  text-align: center;
  animation: blink 3s infinite;
  -webkit-animation: blink 6s infinite;
}

@keyframes blink {
  20%,
  24%,
  55% {
    color: #111;
    text-shadow: none;
  }

  0%,
  19%,
  21%,
  23%,
  25%,
  54%,
  56%,
  100% {
    /*     color: #fccaff;
    text-shadow: 0 0 5px #f562ff, 0 0 15px #f562ff, 0 0 25px #f562ff,
      0 0 20px #f562ff, 0 0 30px #890092, 0 0 80px #890092, 0 0 80px #890092; */
    text-shadow: 0 0 5px #1db98d, 0 0 15px #168f6c, 0 0 20px #1ba47e,
      0 0 40px #108161, 0 0 60px #187259, 0 0 10px #29ce9f, 0 0 98px #108161;
    color: #ffffff;
  }
}
.btn-hover {
  color: #fff;
  cursor: pointer;
  text-align: center !important;
  border: none;
  background-size: 300% 100%;
  border-radius: 50px;
  moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.btn-hover:hover {
  background-position: 100% 0 !important;
  moz-transition: all 0.4s ease-in-out !important;
  -o-transition: all 0.4s ease-in-out !important;
  -webkit-transition: all 0.4s ease-in-out !important;
  transition: all 0.4s ease-in-out !important;
}

.btn-hover:focus {
  outline: none !important;
}

.btn-hover.color-1 {
  background-image: linear-gradient(
    to right,
    #17bb8d,
    #2ac67a,
    #28c77a,
    #2bb673
  ) !important;
  box-shadow: 0 4px 15px 0 rgba(30, 141, 100, 0.75) !important;
}
.btn-hover.color-2 {
  background-image: linear-gradient(
    to right,
    #f5ce62,
    #e48e03,
    #fa7199,
    #e87d19
  ) !important;
  box-shadow: 0 4px 15px 0 rgba(229, 66, 10, 0.75) !important;
}
.btn-hover.color-3 {
  background-image: linear-gradient(
    to right,
    #667eea,
    #764ba2,
    #6b8dd6,
    #8e37d7
  ) !important;
  box-shadow: 0 4px 15px 0 rgba(116, 79, 168, 0.75) !important;
}
.btn-hover.color-4 {
  background-image: linear-gradient(
    to right,
    #fca714,
    #ff9a44,
    #ef9d43,
    #e75516
  ) !important;
  box-shadow: 0 4px 15px 0 rgba(252, 104, 110, 0.75) !important;
}
.btn-hover.color-5 {
  background-image: linear-gradient(
    to right,
    #0ba360,
    #3cba92,
    #30dd8a,
    #2bb673
  ) !important;
  box-shadow: 0 4px 15px 0 rgba(23, 168, 108, 0.75) !important;
}
.btn-hover.color-6 {
  background-image: linear-gradient(
    to right,
    #009245,
    #fcee21,
    #00a8c5,
    #d9e021
  ) !important;
  box-shadow: 0 4px 15px 0 rgba(83, 176, 57, 0.75) !important;
}
.btn-hover.color-7 {
  background-image: linear-gradient(
    to right,
    #6253e1,
    #852d91,
    #a3a1ff,
    #f24645
  ) !important;
  box-shadow: 0 4px 15px 0 rgba(126, 52, 161, 0.75) !important;
}
.btn-hover.color-8 {
  background-image: linear-gradient(
    to right,
    #29323c,
    #485563,
    #2b5876,
    #4e4376
  ) !important;
  box-shadow: 0 4px 15px 0 rgba(45, 54, 65, 0.75) !important;
}
.btn-hover.color-9 {
  background-image: linear-gradient(
    to right,
    #25aae1,
    #4481eb,
    #04befe,
    #3f86ed
  ) !important;
  box-shadow: 0 4px 15px 0 rgba(65, 132, 234, 0.75) !important;
}
.btn-hover.color-10 {
  background-image: linear-gradient(
    to right,
    #ed6ea0,
    #ec8c69,
    #f7186a,
    #fbb03b
  ) !important;
  box-shadow: 0 4px 15px 0 rgba(236, 116, 149, 0.75) !important;
}
.btn-hover.color-11 {
  background-image: linear-gradient(
    to right,
    #eb3941,
    #f15e64,
    #e14e53,
    #e2373f
  ) !important;
  box-shadow: 0 5px 15px rgba(242, 97, 103, 0.4) !important;
}

.headshot-box1 {
  --img-url: url("../images/3.jpg");
}
.headshot-box2 {
  --img-url: url("../images/2.jpg");
}
.headshot-box3 {
  --img-url: url("../images/1.jpg");
}

.headshot-box4 {
  --img-url: url("../images/4.jpg");
}

.headshot-box5 {
  --img-url: url("../images/5.jpg");
}

.headshot {
  flex-shrink: 0;
  margin: 75px;
  height: calc(160px + 6vw);
  width: calc(160px + 6vw);
  border: calc(8px + 0.2vw) solid transparent;
  background-origin: border-box;
  background-clip: content-box, border-box;
  background-size: cover;
  box-sizing: border-box;
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.5);
}

.headshot2 {
  flex-shrink: 0;
  margin: 75px;
  height: calc(160px + 6vw);
  width: calc(160px + 6vw);
  border: calc(8px + 0.2vw) solid transparent;
  background-origin: border-box;
  background-clip: content-box, border-box;
  background-size: cover;
  box-sizing: border-box;
}

.headshot-1 {
  border-radius: 30%;
  background-image: var(--img-url), linear-gradient(#47f9b5, #0fd850);
}

.headshot-2 {
  border-radius: 50%;
  background-image: var(--img-url),
    linear-gradient(to bottom right, #3cff8d, #2b7c3e, #2b86c5);
}

.headshot-3 {
  border-radius: 50% 10%;
  background-image: var(--img-url),
    linear-gradient(to bottom left, #23b17f, #23f96e);
}

.headshot-4 {
  border-radius: 50%;
  background-image: var(--img-url),
    linear-gradient(to bottom right, #3cff8d, #2b7c3e, #2b86c5);
}

.headshot-5 {
  border-radius: 50%;
  background-image: var(--img-url),
    linear-gradient(to bottom right, #3cff8d, #2b7c3e, #2b86c5);
}

.heading {
  font-family: "Playfair Display", serif !important;
  font-size: 9vw !important;
}

@media (min-width: 450px) {
  .underline--magical {
    background-image: linear-gradient(
      -60deg,
      #84fab0 0%,
      #8ff4d2 100%
    ) !important;
    background-repeat: no-repeat !important;
    background-size: 100% 0.1em !important;
    background-position: 0 90% !important;
    transition: background-size 0.25s ease-in !important;
    border-radius: 40px !important;
  }
  .underline--magical:hover {
    background-size: 100% 88% !important;
  }

  .heading-main {
    font-family: "Playfair Display", serif !important;
    font-size: 10vw !important;
  }
}

@media (max-width: 450px) {
  .underline--magical {
    background-image: linear-gradient(
      -60deg,
      #84fab0 0%,
      #8ff4d2 100%
    ) !important;
    background-repeat: no-repeat !important;
    background-size: 100% 0.1em !important;
    background-position: 0 90% !important;
    transition: background-size 0.25s ease-in !important;
    border-radius: 40px !important;
  }
  .underline--magical:hover {
    background-size: 100% 88% !important;
  }

  .heading-main {
    font-family: "Playfair Display", serif !important;
    font-size: 17vw !important;
  }
}

.underline--magical2 {
  background-image: linear-gradient(
    140deg,
    #a3f1cf 0%,
    #aafae9 100%
  ) !important;
  background-repeat: no-repeat !important;
  background-size: 100% 0.2em !important;
  background-position: 0 100% !important;
  transition: background-size 0.25s ease-in !important;
  border-radius: 40px !important;
}
.underline--magical2:hover {
  background-size: 100% 88% !important;
}

.underline--magical3 {
  background-image: linear-gradient(
    140deg,
    #17b68e 0%,
    #0aeacc 100%
  ) !important;
  background-repeat: no-repeat !important;
  background-size: 100% 0.08em !important;
  background-position: 0 100% !important;
  transition: background-size 0.25s ease-in !important;
  border-radius: 40px !important;
  cursor: pointer !important;
}
.underline--magical3:hover {
  background-size: 100% 88% !important;
  color: #fff !important;
  padding: 5px !important;
}

.home-text {
  --b: 0.5em;
  /* border width */
  --c: 3em;
  /* corner size */
  --r: 2em;
  /* corner rounding */
  position: relative;
  margin: 0.7em auto;
  border: solid var(--b) transparent;
  padding: 0.31em;
  max-width: 23em;
  align-items: center;
  align-content: center;
  text-align: center;
  font: 1.25em ubuntu, sans-serif;
}
.home-text::before {
  position: absolute;
  z-index: -1;
  inset: calc(-1 * var(--b));
  border: inherit;
  border-radius: var(--r);
  background: linear-gradient(
      rgb(25, 255, 201),
      rgb(20, 255, 251),
      rgb(0, 128, 113)
    )
    border-box;
  --corner: conic-gradient(
      from -90deg at var(--c) var(--c),
      rgb(0, 255, 149) 25%,
      #0000 0
    )
    0 0 / calc(100% - var(--c)) calc(100% - var(--c)) border-box;
  --inner: conic-gradient(rgb(0, 251, 255) 0 0) padding-box;
  -webkit-mask: var(--corner), var(--inner);
  -webkit-mask-composite: source-out;
  mask: var(--corner) subtract, var(--inner);
  content: "";
}

.carousel-container {
  position: relative;
}

.carousel {
  overflow: hidden;
}

.carousel-wrapper {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.carousel-wrapper img {
  height: 200px;
  margin-bottom: 13px;
  width: 100%;
}

.carousel-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  font-size: 2em;
  cursor: pointer;
}

.carousel-button.prev {
  left: 0;
}

.carousel-button.next {
  right: 0;
}

.blob {
  position: absolute;
  top: 0;
  left: 0;
  fill: #023f92;
  width: 20vmax;
  z-index: -1;
  animation: move 10s ease-in-out infinite;
  transform-origin: 50% 50%;
}

.blob-body {
  margin: 0;
  padding: 0;
  overflow: hidden !important;
  position: relative !important;

  display: flex !important;
  align-items: center !important;
}

@keyframes move {
  0% {
    transform: scale(1) translate(10px, -30px);
  }
  38% {
    transform: scale(0.8, 1) translate(80vw, 30vh) rotate(160deg);
  }
  40% {
    transform: scale(0.8, 1) translate(80vw, 30vh) rotate(160deg);
  }
  78% {
    transform: scale(1.3) translate(0vw, 50vh) rotate(-20deg);
  }
  80% {
    transform: scale(1.3) translate(0vw, 50vh) rotate(-20deg);
  }
  100% {
    transform: scale(1) translate(10px, -30px);
  }
}

.box-shadow6 {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
    rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
    rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset !important;
}

.side2 {
  position: absolute;
  width: 50vw;
  top: 0;
  bottom: 0;
  z-index: 10;
}
.side.left2 {
  left: 0;
}
.side.left2:hover ~ .wrapper2 .front2 {
  transform: matrix3d(
      0.94,
      0,
      0.34,
      -0.0002,
      0,
      1,
      0,
      0,
      -0.34,
      0,
      0.94,
      0,
      1,
      0,
      10,
      1
    )
    translateX(-10px);
}
.side.left2:hover ~ .wrapper2 .back2 {
  transform: matrix3d(
      0.94,
      0,
      0.34,
      -0.00025,
      0,
      1,
      0,
      0,
      -0.34,
      0,
      0.94,
      0,
      1,
      0,
      10,
      1
    )
    translateX(30px);
}
.side.right2 {
  right: 0;
}
.side.right2:hover ~ .wrapper2 .front2 {
  transform: matrix3d(
      0.94,
      0,
      0.34,
      0.0002,
      0,
      1,
      0,
      0,
      -0.34,
      0,
      0.94,
      0,
      1,
      0,
      10,
      1
    )
    translateX(30px);
}
.side.right2:hover ~ .wrapper2 .back2 {
  transform: matrix3d(
      0.94,
      0,
      0.34,
      0.0005,
      0,
      1,
      0,
      0,
      -0.34,
      0,
      0.94,
      0,
      1,
      0,
      10,
      1.1
    )
    translateX(-100px);
}

.side {
  position: absolute;
  width: 50vw;
  top: 0;
  bottom: 0;
  z-index: 10;
}
.side.left {
  left: 0;
}
.side.left:hover ~ .wrapper .front {
  transform: matrix3d(
      0.94,
      0,
      0.34,
      -0.0002,
      0,
      1,
      0,
      0,
      -0.34,
      0,
      0.94,
      0,
      1,
      0,
      10,
      1
    )
    translateX(-10px);
}
.side.left:hover ~ .wrapper .back {
  transform: matrix3d(
      0.94,
      0,
      0.34,
      -0.00025,
      0,
      1,
      0,
      0,
      -0.34,
      0,
      0.94,
      0,
      1,
      0,
      10,
      1
    )
    translateX(30px);
}
.side.right {
  right: 0;
}
.side.right:hover ~ .wrapper .front {
  transform: matrix3d(
      0.94,
      0,
      0.34,
      0.0002,
      0,
      1,
      0,
      0,
      -0.34,
      0,
      0.94,
      0,
      1,
      0,
      10,
      1
    )
    translateX(30px);
}
.side.right:hover ~ .wrapper .back {
  transform: matrix3d(
      0.94,
      0,
      0.34,
      0.0005,
      0,
      1,
      0,
      0,
      -0.34,
      0,
      0.94,
      0,
      1,
      0,
      10,
      1.1
    )
    translateX(-100px);
}

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.wrapper2 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

@media (min-width: 450px) {
  .image {
    perspective: 200px;
    transition: all 0.2s ease;
    background-image: url("../images/agri3.jpeg");
    height: 340px;
    filter: saturate(1.5);
    width: 500px;
    background-size: cover;
    box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.8);
    border-radius: 10px;
  }
  .image2 {
    perspective: 200px;
    transition: all 0.2s ease;
    background-image: url("../images/14.jpg");
    height: 340px;
    filter: saturate(1.5);
    width: 500px;
    background-size: cover;
    box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.8);
    border-radius: 10px;
  }
}

@media (max-width: 450px) {
  .image {
    perspective: 200px;
    transition: all 0.2s ease;
    background-image: url("../images/agri3.jpeg");
    height: 300px;
    filter: saturate(1.5);
    width: 260px;
    background-size: cover;
    box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.8);
    border-radius: 10px;
  }
  .image2 {
    perspective: 200px;
    transition: all 0.2s ease;
    background-image: url("../images/14.jpg");
    height: 300px;
    filter: saturate(1.5);
    width: 260px;
    background-size: cover;
    box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.8);
    border-radius: 10px;
  }
}

@media (max-width: 380px) {
  .image {
    perspective: 200px;
    transition: all 0.2s ease;
    background-image: url("../images/agri3.jpeg");
    height: 300px;
    filter: saturate(1.5);
    width: 220px;
    background-size: cover;
    box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.8);
    border-radius: 10px;
  }
  .image2 {
    perspective: 200px;
    transition: all 0.2s ease;
    background-image: url("../images/14.jpg");
    height: 300px;
    filter: saturate(1.5);
    width: 220px;
    background-size: cover;
    box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.8);
    border-radius: 10px;
  }
}

.front {
  margin-right: 50px;
  z-index: 1;
}

.back {
  margin-left: 100px;
  margin-top: -150px;
  background-position: bottom;
}

.front2 {
  margin-right: 50px;
  z-index: 1;
}

.back2 {
  margin-left: 100px;
  margin-top: -150px;
  background-position: bottom;
}

.body {
  overflow: hidden;
  color: #fff;
  animation: fadeIn 500ms reverse;
}

.gooey {
  background-image: linear-gradient(120deg, #34e0f0 0%, #b400ff 100%);
  border-radius: 42% 58% 70% 30% / 45% 45% 55% 55%;
  width: 150px;
  height: 144px;
  animation: morph 3s linear infinite;
  transform-style: preserve-3d;
  outline: 1px solid transparent;
  will-change: border-radius;
}
.gooey:before,
.gooey:after {
  content: "";
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 42% 58% 70% 30% / 45% 45% 55% 55%;
  box-shadow: 5px 5px 89px rgba(0, 102, 255, 0.21);
  will-change: border-radius, transform, opacity;
  animation-delay: 200ms;
  background-image: linear-gradient(
    120deg,
    rgba(0, 67, 255, 0.55) 0%,
    rgba(0, 103, 255, 0.89) 100%
  );
}

.gooey:before {
  animation: morph 3s linear infinite;
  opacity: 0.21;
  animation-duration: 1.5s;
}

.gooey:after {
  animation: morph 3s linear infinite;
  animation-delay: 400ms;
  opacity: 0.89;
  content: " Exceptional Agricultural Inoculums";
  line-height: 120px;
  text-indent: -21px;
}

@keyframes morph {
  0%,
  100% {
    border-radius: 42% 58% 70% 30% / 45% 45% 55% 55%;
    transform: translate3d(0, 0, 0) rotateZ(0.01deg);
  }
  34% {
    border-radius: 70% 30% 46% 54% / 30% 29% 71% 70%;
    transform: translate3d(0, 5px, 0) rotateZ(0.01deg);
  }
  50% {
    opacity: 0.89;
    transform: translate3d(0, 0, 0) rotateZ(0.01deg);
  }
  67% {
    border-radius: 100% 60% 60% 100% / 100% 100% 60% 60%;
    transform: translate3d(0, -3px, 0) rotateZ(0.01deg);
  }
}

@keyframes fadeIn {
  100% {
    transform: scale(1.03);
    opacity: 0;
  }
}

.thumb {
  width: 450px;
  height: 380px;
  margin: 30px auto;
  perspective: 1000px;
}

.thumb a {
  display: block;
  width: 100%;
  height: 100%;
  background: linear-gradient(
      rgba(158, 156, 156, 0.4),
      rgba(143, 139, 139, 0.4)
    ),
    url("../images/agri2.jpg");
  background-size: 0, cover;
  transform-style: preserve-3d;
  transform: rotateX(55deg);
  transition: all 0.5s;
}

.thumb a:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 36px;
  background: inherit;
  background-size: cover, cover;
  background-position: bottom;
  transform: rotateX(90deg);
  transform-origin: bottom;
}
.thumb a span {
  color: white;
  text-transform: uppercase;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  font: bold 12px/36px "Open Sans";
  text-align: center;
  transform: rotateX(-89.99deg);
  transform-origin: top;
  z-index: 1;
}
.thumb a:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  transition: all 0.5s;
  opacity: 0.15;
  transform: rotateX(95deg) translateZ(-80px) scale(0.75);
  transform-origin: bottom;
}

.grid-wrapper {
  display: flex;
  flex-wrap: wrap;
  height: 370px;
  overflow: hidden;
  position: relative;
  width: 500px;
  border-radius: 10px;
}

.grid-item {
  height: 14.2857142857%;
  width: 14.2857142857%;
}
.grid-item:hover ~ img {
  height: 150%;
  width: 150%;
}
.grid-item:hover:nth-of-type(7n + 1) ~ img {
  left: 0px;
}
.grid-item:hover:nth-of-type(1n + 1) ~ .zoom-image {
  top: 0px;
}
.grid-item:hover:nth-of-type(7n + 2) ~ img {
  left: -33.3333333333px;
}
.grid-item:hover:nth-of-type(1n + 8) ~ .zoom-image {
  top: -33.3333333333px;
}
.grid-item:hover:nth-of-type(7n + 3) ~ img {
  left: -66.6666666667px;
}
.grid-item:hover:nth-of-type(1n + 15) ~ .zoom-image {
  top: -66.6666666667px;
}
.grid-item:hover:nth-of-type(7n + 4) ~ img {
  left: -100px;
}
.grid-item:hover:nth-of-type(1n + 22) ~ .zoom-image {
  top: -100px;
}
.grid-item:hover:nth-of-type(7n + 5) ~ img {
  left: -133.3333333333px;
}
.grid-item:hover:nth-of-type(1n + 29) ~ .zoom-image {
  top: -133.3333333333px;
}
.grid-item:hover:nth-of-type(7n + 6) ~ img {
  left: -166.6666666667px;
}
.grid-item:hover:nth-of-type(1n + 36) ~ .zoom-image {
  top: -166.6666666667px;
}
.grid-item:hover:nth-of-type(7n + 7) ~ img {
  left: -200px;
}
.grid-item:hover:nth-of-type(1n + 43) ~ .zoom-image {
  top: -200px;
}

.zoom-image {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transition: all 0.25s;
  width: 100%;
  z-index: -1;
}

.grid-item {
  background-color: rgba(48, 255, 200, 0.05);
}
.grid-item:nth-of-type(2n) {
  background-color: rgba(0, 0, 0, 0.15);
}

.grid-wrapper {
  margin: 0 auto;
}
